import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carrusel from "react-slick";
import Styles from './slider.module.scss'
import Default from "../../../assets/img/default.jpg";

const Slider = ({slides}) => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        adaptiveHeight: true,
        arrows: false,
        draggable: true,
    };
    return (
        <div className={Styles.Slider}>
            <Carrusel {...settings}>
                {/*Loop para crear los slides*/}
                {slides.length &&
                    slides.map((slide) =>
                        <div className={Styles.item} key={slide.id}>
                            <a href={slide.fields.link}>
                                <img src={ slide.fields.imagen ? slide.fields.imagen[0].thumbnails.large.url : Default} alt=""/>
                                <h3>{slide.fields.texto}</h3>
                            </a>
                        </div>
                    )
                }
            </Carrusel>
        </div>
    );
};

export default Slider;
import { useState } from 'react'
import Styles from './profile.module.scss'
import Header from "../../common/header/Header";
import Default from "../../../assets/img/default.jpg";
import { useUser } from "../../../contexts/AppContext";
import {useHistory} from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { uploadPhotoApi } from "../../../api/userApi";
import Cryptr from "cryptr";
import Loading from "../../../assets/img/loading.gif";
import toast from 'react-hot-toast';

const Profile = () => {
    const [isLoading, setIsloding] = useState(false)
    const { user, setUser, setUserToken, userToken } = useUser();
    const { push } = useHistory()
    const cryptr = new Cryptr(userToken);

    const logout = () => {
        setUser([])
        setUserToken('')
        localStorage.removeItem('MKT_USER')
        push('/')
    }

    const uploadPhoto = async (e) => {
        setIsloding(true)
        let storedUser = localStorage.getItem('MKT_USER')
        const decryptedString = cryptr.decrypt(storedUser);
        const datos = decryptedString.split(',')

        const email = user[0].fields.correo
        const password = datos[1]
        const image = e.target.files[0]

        const res = await uploadPhotoApi(email, password, image, user[0].id ,user[0].fields.nombre)
        if(res.records.length){
            toast.success('Imagen actualizada');
            setUser(() => ([...[], ...res.records]))
            setIsloding(false)
        }
    }

    return (
        <div className={Styles.Profile}>
            <Header/>
            <div className={Styles.content}>
                <div className={Styles.portada}>
                    <img src="https://picsum.photos/200/300" alt=""/>
                </div>
                <div className={Styles.avatar}>
                    <div className={Styles.photo}>
                        <input type="file" id="photo" onChange={uploadPhoto} />
                        {
                            isLoading ? <img src={Loading} alt=""/> :
                            <>
                            <img src={ user[0].fields.foto? user[0].fields.foto[0].url : Default} />
                            <label htmlFor="photo">
                                <FiEdit/>
                            </label>
                            </>
                        }
                    </div>
                    <h3>{user[0].fields.nombre}</h3>
                </div>
                <div className={Styles.datos}>
                    <ul>
                        <li>
                            <h4>Nombres y apellidos:</h4>
                            <p>{user[0].fields.nombre}</p>
                        </li>
                        <li>
                            <h4>Business Unit:</h4>
                            <p>{user[0].fields.rol}</p>
                        </li>
                        <li>
                            <h4>Geografía:</h4>
                            <p>{user[0].fields.pais}</p>
                        </li>
                        <li>
                            <h4>Mail:</h4>
                            <p>{user[0].fields.correo}</p>
                        </li>
                    </ul>
                </div>
            <button onClick={()=>logout()}>Cerrar sesión</button>
            </div>
        </div>
    );
};

export default Profile;
import {useEffect, useState} from 'react';
import Styles from "./login.module.scss";
import { Link } from "react-router-dom";
import {loginApi, userTokenApi} from "../../../api/userApi";
import Loader from "../../common/loader/Loader";
import { useUser } from "../../../contexts/AppContext";
import {Logo, TopBlue, TopGreen, BottomImage} from "../../../assets/img";
import Cryptr from "cryptr";
import toast from 'react-hot-toast';

function Login(props) {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [isloading, setIsloading] = useState(false)
    const { setUser, userToken } = useUser()
    const cryptr = new Cryptr(userToken);

    const login = async(e) => {
        e.preventDefault();
        setIsloading(true)
        if(email){
                    if(password){
                        const res = await loginApi(email, password);
                        if(res === 'error' || res === 'Usuario inválido'){
                            setIsloading(false)
                            toast.error('Por favor, inténtalo de nuevo');
                            return
                        }
                        //login correcto
                        if(res.length){
                            await userTokenApi(res[0].id, userToken)
                            const encryptedCredentials = cryptr.encrypt(`${email},${password}`);
                            localStorage.setItem('MKT_USER', encryptedCredentials)
                            setUser(prevUser => ([...prevUser, ...res]))
                        }
            }
        }
    }

    return (
        <div className={Styles.login}>
            <img src={TopGreen} alt=""/>
            <div className={Styles.logo}>
                <img src={Logo} alt=""/>
            </div>
            <div className={Styles.mainContent}>
                <div className={Styles.heading}>
                    <h2>¡Hola de nuevo!</h2>
                    <p>Ingresa tus datos para iniciar sesión</p>
                </div>
                <form className={Styles.form} onSubmit={login}>
                    <input type="email" placeholder="Correo electrónico corporativo" onKeyUp={(e) => setEmail(e.target.value)} required/>
                    <input type="password" placeholder="Contraseña" onKeyUp={(e) => setPassword(e.target.value)} required/>
                    {/*<input type="text" id="token_input" placeholder="token"/>*/}
                    <button type="submit">
                        {
                            isloading ? <Loader/>
                                :'Iniciar sesión'
                        }
                    </button>
                </form>
                <Link to="/change">¿Olvidaste tu contraseña?</Link>
            </div>
            <img src={BottomImage} alt=""/>
        </div>
    );
}

export default Login;
import Styles from './notifications.module.scss'
import Header from "../../common/header/Header";
import {Link} from "react-router-dom";
import Default from "../../../assets/img/default.jpg";
import {useUser} from "../../../contexts/AppContext";
import { formatDistance } from 'date-fns'
import { es } from 'date-fns/locale'

const Notifications = () => {
    const { notifications } = useUser()

    const formating = (unformated)=>{
        if(unformated.length){
            const dateAndTime = unformated.split(' ')
            const date = dateAndTime[0].split('-')
            const time = dateAndTime[1].split(':')
            const formated = formatDistance(
                new Date(date[0], date[1]-1, date[2], time[0], time[1], 0),
                new Date(),
                { addSuffix: true, locale: es }
            )
            return formated
        }
    }

    return (
        <div className={Styles.Notifications}>
            <Header/>
            <div className={Styles.content}>
                <h3>Mis notificaciones</h3>
                <hr/>
                {
                    notifications.length ?
                        notifications.map((not)=> <Link to='#'>
                            <div className={Styles.card}>
                                <img src={not.fields.imagen ? not.fields.imagen[0].thumbnails.large.url : Default} alt=""/>
                                <div>
                                    <p><strong>{not.fields.titulo}</strong> {not.fields.mensaje}</p>
                                    <span>{formating(not.fields.fecha? not.fields.fecha :'')}</span>
                                </div>
                            </div>
                            <hr/>
                        </Link>) : 'No tienes notificaciones'
                }

            </div>
        </div>
    );
};

export default Notifications;
import Styles from './changePassword.module.scss'
import {Link, useHistory} from "react-router-dom";
import {useState} from "react";
import {Logo, TopBlue, BottomImage} from "../../../assets/img";
import { sendEmailApi } from "../../../api/userApi";
import toast from 'react-hot-toast';
import Loader from "../../common/loader/Loader";

const ChangePassword = () => {
    const [email, setEmail] = useState('')
    const [isloading, setIsloading] = useState(false)
    const { push } = useHistory()
    const sendEmail = async(e) => {
        e.preventDefault();
        setIsloading(true)
        if(email.length){
            const res = await sendEmailApi(email)
            if(res == 'error'){
                toast.error('Por favor, inténtalo de nuevo')
                setIsloading(false)
                return
            }
            toast.success(res.data.message)
            push('/')
        }
    }

    return (
        <div className={Styles.ChangePassword}>
            <img src={TopBlue} alt=""/>
            <div className={Styles.logo}>
                <img src={Logo} alt=""/>
            </div>
            <div className={Styles.mainContent}>
                <div className={Styles.heading}>
                    <h2>¿Olvidaste tu contraseña?</h2>
                    <p>Ingresa tu correo electrónico y revisa tu bandeja de entrada para cambiar tu contraseña</p>
                </div>
            <form className={Styles.form} onSubmit={sendEmail}>
                <input type="email" placeholder="Correo electrónico" onKeyUp={(e) => setEmail(e.target.value)} required/>
                <button type="submit">
                    {
                        isloading ? <Loader/>
                            :'Enviar correo'
                    }
                </button>
            </form>
            </div>
            <img src={BottomImage} alt=""/>
        </div>
    );
};

export default ChangePassword;
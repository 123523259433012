import { useEffect } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Login from "../components/pages/login/Login";
import Home from "../components/pages/home/Home";
import ChangePassword from "../components/pages/changePassword/ChangePassword";
import { useUser } from "../contexts/AppContext";
import Profile from "../components/pages/profile/Profile";
import Notifications from "../components/pages/notifications/Notifications";
import SplashScreen from "../components/pages/splashScreen/SplashScreen";
import { getNotificationsApi } from "../api/contentApi";
import { Toaster } from 'react-hot-toast';

const MainRouter = () => {
    const { user, userToken, setNotifications } = useUser();

    useEffect(()=>{
        const getNotifications = async () => {
            if(user.length){
                const resp = await getNotificationsApi(user[0].fields.rol);
                setNotifications(resp.records)
            }
        }
        getNotifications()
    },[user])

    useEffect(() => {
        /*if(process.env.NODE_ENV == 'development'){*/
            localStorage.setItem('token_marco_mkt','asdbvicabvpifcnweoicmwwjdáe')
        /*}*/
    },[])

    return(
        <Router>
            <Switch>
                <div className="container">
                    {
                        !userToken ? <Route component={SplashScreen} /> :
                        !user.length ?
                            (<>
                                    <Route exact path="/" component={Login} />
                                    <Route exact path="/change" component={ChangePassword}/>
                            </>)
                            : (
                                <>
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/profile" component={Profile} />
                                    <Route exact path="/notifications" component={Notifications} />
                                </>
                            )
                    }
                    <Toaster position="bottom-center" toastOptions={{duration: 5000}}/>
                </div>
            </Switch>
        </Router>
    );
}

export default MainRouter;
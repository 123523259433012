import { useState, useEffect } from "react";
import Styles from './home.module.scss'
import Header from "../../common/header/Header";
import Slider from "../../common/slider/Slider";
import CardList from "../../common/cardList/CardList";
import {getContentApi} from "../../../api/contentApi";
import {useUser} from "../../../contexts/AppContext";
import {BoxIcon, CalendarIcon, HeartIcon, PlanetIcon, PlantIcon, PuzzleIcon} from "../../../assets/img";

const Home = () => {
    const [sliderContent, setSliderContent] = useState([])
    const [ newsContent, setNewsContent ] = useState([])
    const [ xperContent, setXperContent ] = useState([])
    const [ equipoContent, setEquipoContent ] = useState([])
    const [ inspiracionContent, setInspiracionContent ] = useState([])
    const [ corazonContent, setCorazonContent ] = useState([])
    const [ razonContent, setRazonContent ] = useState([])
    const { user } = useUser()

    useEffect(() =>{
        const getContent = async () => {
            const rol = user[0].fields.rol.toString()
            const res = await getContentApi(rol)
            if(res.data.records){
                let slides = res.data.records.filter((item)=> item.fields.categoria == "Slider")
                let news = res.data.records.filter((item)=> item.fields.categoria == "Marco News")
                let xpert = res.data.records.filter((item)=> item.fields.categoria == "XPER Journey")
                let inspiracion = res.data.records.filter((item)=> item.fields.categoria == "Somos inspiración")
                let corazon = res.data.records.filter((item)=> item.fields.categoria == "Somos Corazón")
                let razon = res.data.records.filter((item)=> item.fields.categoria == "Somos Razón")
                let equipo = res.data.records.filter((item)=> item.fields.categoria == "Somos Equipo")
                setSliderContent(slides)
                setNewsContent(news)
                setXperContent(xpert)
                setInspiracionContent(inspiracion)
                setCorazonContent(corazon)
                setRazonContent(razon)
                setEquipoContent(equipo)
            }
        }
        getContent()
    },[])

    return (
        <div className={Styles.Home}>
            <Header/>
            <div className={Styles.container}>
                <Slider slides={sliderContent}/>
                {
                    newsContent.length ?
                    <div className={Styles.categoryList}>
                        <h3>
                            <img src={CalendarIcon} alt=""/>
                            Marco News
                        </h3>
                        <hr/>
                        <CardList items={newsContent}/>
                    </div> : ''
                }
                {
                xperContent.length ?
                    <div className={Styles.categoryList}>
                        <h3>
                            <img src={BoxIcon} alt=""/>
                            XPER Journey
                        </h3>
                        <hr/>
                        <CardList style="full" items={xperContent}/>
                    </div> : ''
                }
                {
                    inspiracionContent.length ?
                    <div className={Styles.categoryList}>
                        <h3>
                            <img src={PlantIcon} alt=""/>
                            Somos Inspiración
                        </h3>
                        <hr/>
                        <CardList style="chip" listStyle="vetical" items={inspiracionContent}/>
                    </div> : ''
                }
                {
                    corazonContent.length ?
                    <div className={Styles.categoryList}>
                        <h3>
                            <img src={HeartIcon} alt=""/>
                            Somos Corazón
                        </h3>
                        <hr/>
                        <CardList style="chip" listStyle="vetical" items={corazonContent}/>
                    </div> : ''
                }
                {
                    razonContent.length ?
                    <div className={Styles.categoryList}>
                        <h3>
                            <img src={PuzzleIcon} alt=""/>
                            Somos Razón
                        </h3>
                        <hr/>
                        <CardList style="chip" listStyle="vetical" items={razonContent}/>
                    </div> : ''
                }
                {
                    equipoContent.length ?
                    <div className={Styles.categoryList}>
                        <h3>
                            <img src={PlanetIcon} alt=""/>
                            Somos Equipo
                        </h3>
                        <hr/>
                        <CardList style="chip" listStyle="vetical" items={equipoContent}/>
                    </div> : ''
                }
            </div>
        </div>
    );
};

export default Home;
import {useContext, createContext, useState} from "react";

const AppContext = createContext();

export const AppProivider = ({children}) => {
    const[ user, setUser ] = useState([])
    const[ userToken, setUserToken ] = useState('')
    const [ notifications, setNotifications ] = useState([])

    const value = [
        user, setUser,
        userToken, setUserToken,
        notifications, setNotifications
    ]
    return <AppContext.Provider value={value}>
        {children}
    </AppContext.Provider>
}

export const useUser = () => {
    const[ user, setUser, userToken, setUserToken, notifications, setNotifications ] = useContext(AppContext)
    return { user, setUser, userToken, setUserToken, notifications, setNotifications }
}

// export const useNotifications = () => {
//     const [ notifications, setNotifications ] = useContext(AppContext)
//     return { notifications, setNotifications }
// }
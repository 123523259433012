import {airtableApi} from "./api";
import axios from 'axios'

export const getContentApi = async (rol) => {
    try{
        const res = await axios.get(`${airtableApi}contenidos?filterByFormula=AND(FIND("${rol}", ARRAYJOIN({rol_nombre})) > 0,{estado}="mostrar")`,{
            headers: {
                Authorization:'Bearer keyKUmWHU1iVvWgPc'
            }
        })
        return res;
    }catch (error){
        return error;
    }
}

export const getNotificationsApi = async (rol) => {
    try {
        const res = await axios.get(`${airtableApi}notificaciones?filterByFormula=AND(FIND("${rol}",rol_nombre),FIND(\"enviado\",estado))&sort%5B0%5D%5Bfield%5D=fecha&sort%5B0%5D%5Bdirection%5D=desc`,{
            headers: {
                Authorization:'Bearer keyKUmWHU1iVvWgPc'
            }
        })
        return res.data
    }catch(error){

    }
}
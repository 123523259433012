import {airtableApi, marcoMktApi} from "./api";
import axios from 'axios';

export const loginApi = async(email, password) => {
    try{
        const res = await axios.post(`${marcoMktApi}login`,{
                email:email,
                password:password,
        })
        if(!res.data[0]){
            return 'Usuario inválido'
        }
        if(res.data[0].fields.estado != "permitido") return;
        return res.data
    }catch(error){
        return 'error'
    }
}
export const userTokenApi = async(id, token) => {
    try{
        const res = await axios.patch(`${airtableApi}usuarios`,
            {
                "records": [
                    {
                        "id": id,
                        "fields": {
                            "token_app": token
                        }
                    }
                ]
            },
            {
                headers: {
                    Authorization:'Bearer keyKUmWHU1iVvWgPc'
                }
            }
        )
        return res;
    }catch(error){
        return error
    }
}

export const sendEmailApi = async (email) => {
    try{
        const res  = await axios.get(`${marcoMktApi}reset?correo=${email}`)
        return res
    }
    catch(error){
        return 'error'
    }
}

export const uploadPhotoApi = async (email,password,image, id, name) => {
    let data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("image", image);
    try{
        const res = await axios.post(`${marcoMktApi}user_image`,data)
        if(res.data.status == 'success'){
            const img = await axios.patch(`${airtableApi}usuarios`,
                {
                    "records": [
                        {
                            "id": id,
                            "fields": {
                                "foto": [
                                    {
                                        "url": res.data.ruta,
                                        "filename": name+id+'.jpg'
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    headers: {
                        Authorization:'Bearer keyKUmWHU1iVvWgPc'
                    }
                }
            )
            return img.data
        }
    }catch(error){

    }
}

import Styles from './header.module.scss'
import Logo from "../../../assets/img/logo.png";
import {Link} from "react-router-dom";
import Navigation from "../navigation/Navigation";

const Header = () => {
    return (
        <div className={Styles.Header}>
            <header>
                <Link to="/">
                    <img src={Logo} alt=""/>
                </Link>
            </header>
            <Navigation/>
        </div>
    );
};

export default Header;
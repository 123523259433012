import Styles from './navigation.module.scss'
import {Link, useLocation} from "react-router-dom";
import {
    HomeIcon, HomeIconFull,
    NotiIcon, NotiIconFull,
    ProfileIcon, ProfileIconFull
} from "../../../assets/img";

const Navigation = () => {
    const {pathname} = useLocation();

    return (
        <nav className={Styles.Navigation}>
            <Link to="/">
                {
                    pathname == '/'?
                        <img src={HomeIconFull} alt=""/>
                        :<img src={HomeIcon} alt=""/>
                }

            </Link>
            <Link to="/notifications">
                {
                    pathname == '/notifications'?
                        <img src={NotiIconFull} alt=""/>
                        : <img src={NotiIcon} alt=""/>
                }
            </Link>
            <Link to="/profile">
                {
                    pathname == '/profile'?
                        <img src={ProfileIconFull} alt=""/>
                        : <img src={ProfileIcon} alt=""/>
                }

            </Link>
        </nav>
    );
};

export default Navigation;
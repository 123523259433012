import Styles from './splashScreen.module.scss'
import { Logo, TopGreen, BottomImage } from "../../../assets/img";
import {useUser} from "../../../contexts/AppContext";
import { useEffect } from 'react'
import Cryptr from "cryptr";
import {loginApi, userTokenApi} from "../../../api/userApi";

const SplashScreen = () => {
    const { setUser, setUserToken } = useUser();

    useEffect(() => {
        const getToken = () => {
            let token = localStorage.getItem('token_marco_mkt');
            let storedUser = localStorage.getItem('MKT_USER')
            if(token && storedUser){
                const cryptr = new Cryptr(token);
                const decryptedString = cryptr.decrypt(storedUser);
                if(decryptedString){
                    const datos = decryptedString.split(',')
                    login(datos[0],datos[1], token);
                }
            }else{
                if(token){
                        setUserToken(token);
                }else{
                    setTimeout(()=> getToken(), 1000)
                }
            }
        }
        getToken()
    })

    const login = async(email, password, token) =>{
        const res = await loginApi(email, password);
        if(res === 'error' || res === 'Usuario inválido'){
            setUser([])
            setUserToken('')
            localStorage.removeItem('MKT_USER')
            return
        }
        //login correcto
        setUser(prevUser => ([...prevUser, ...res]))
        await userTokenApi([0].id, token)
        setUserToken(token);
    }

    return (
        <div className={Styles.SplashScreen}>
            <img src={TopGreen} alt=""/>
            <img src={Logo} alt="" className={Styles.logo}/>
            <img src={BottomImage} alt=""/>
        </div>
    );
};

export default SplashScreen;
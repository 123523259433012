import Styles from './cardList.module.scss'
import CardItem from "../cardItem/CardItem";
import Default from "../../../assets/img/default.jpg";

const CardList = ({style="normal", items = [], listStyle="horizontal"}) => {

    return (
        <div className={ listStyle=='vetical' ? Styles.CardListVertical : Styles.CardList}>
            {items.length ?
                items.map((item) =>
                    <CardItem key={item.id}
                              style={style}
                              text={item.fields.texto}
                              url={item.fields.link}
                              img={ item.fields.imagen ? item.fields.imagen[0].thumbnails.large.url : Default }
                              linkType={item.fields.tipo_link}
                    />
                ) : 'No hay contenido para mostrar'
            }
        </div>
    );
};

export default CardList;
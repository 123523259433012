import './cardItem.scss'
import Default from "../../../assets/img/default.jpg";
import { LinkIcon, PdfIcon, NotesIcon } from "../../../assets/img";

const CardItem = ({style = 'normal', url, text, img = Default, linkType}) => {

    return (
        <a href={url} className={`CardItem__${style}`}>
            <div className="image">
              <img src={ style=='chip'? linkType=="pdf"? PdfIcon : LinkIcon : img} alt={text}/>
            </div>
                {
                    style=='full' ? <img src={NotesIcon} alt="" className="noteIcon"/> : ''
                }
            <span>{text}</span>
        </a>
    );
};

export default CardItem;